exports.components = {
  "component---src-pages-2021-financials-report-js": () => import("./../../../src/pages/2021-financials-report.js" /* webpackChunkName: "component---src-pages-2021-financials-report-js" */),
  "component---src-pages-2022-financials-report-js": () => import("./../../../src/pages/2022-financials-report.js" /* webpackChunkName: "component---src-pages-2022-financials-report-js" */),
  "component---src-pages-2023-financials-report-js": () => import("./../../../src/pages/2023-financials-report.js" /* webpackChunkName: "component---src-pages-2023-financials-report-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-account-js": () => import("./../../../src/pages/add-account.js" /* webpackChunkName: "component---src-pages-add-account-js" */),
  "component---src-pages-agm-js": () => import("./../../../src/pages/agm.js" /* webpackChunkName: "component---src-pages-agm-js" */),
  "component---src-pages-amalgamation-statement-js": () => import("./../../../src/pages/amalgamation-statement.js" /* webpackChunkName: "component---src-pages-amalgamation-statement-js" */),
  "component---src-pages-answers-js": () => import("./../../../src/pages/answers.js" /* webpackChunkName: "component---src-pages-answers-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-autosave-js": () => import("./../../../src/pages/autosave.js" /* webpackChunkName: "component---src-pages-autosave-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-client-agreement-js": () => import("./../../../src/pages/client-agreement.js" /* webpackChunkName: "component---src-pages-client-agreement-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-direct-debit-agreement-js": () => import("./../../../src/pages/direct-debit-agreement.js" /* webpackChunkName: "component---src-pages-direct-debit-agreement-js" */),
  "component---src-pages-family-js": () => import("./../../../src/pages/family.js" /* webpackChunkName: "component---src-pages-family-js" */),
  "component---src-pages-fund-fact-sheets-js": () => import("./../../../src/pages/fund-fact-sheets.js" /* webpackChunkName: "component---src-pages-fund-fact-sheets-js" */),
  "component---src-pages-groups-js": () => import("./../../../src/pages/groups.js" /* webpackChunkName: "component---src-pages-groups-js" */),
  "component---src-pages-hello-january-js": () => import("./../../../src/pages/hello-january.js" /* webpackChunkName: "component---src-pages-hello-january-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investor-relations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-invite-and-earn-referral-program-js": () => import("./../../../src/pages/invite-and-earn-referral-program.js" /* webpackChunkName: "component---src-pages-invite-and-earn-referral-program-js" */),
  "component---src-pages-ke-js": () => import("./../../../src/pages/ke.js" /* webpackChunkName: "component---src-pages-ke-js" */),
  "component---src-pages-kenya-js": () => import("./../../../src/pages/kenya.js" /* webpackChunkName: "component---src-pages-kenya-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recover-account-js": () => import("./../../../src/pages/recover-account.js" /* webpackChunkName: "component---src-pages-recover-account-js" */),
  "component---src-pages-select-goal-js": () => import("./../../../src/pages/select-goal.js" /* webpackChunkName: "component---src-pages-select-goal-js" */),
  "component---src-pages-smartfeatures-js": () => import("./../../../src/pages/smartfeatures.js" /* webpackChunkName: "component---src-pages-smartfeatures-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-tulumbe-js": () => import("./../../../src/pages/tulumbe.js" /* webpackChunkName: "component---src-pages-tulumbe-js" */),
  "component---src-pages-watch-js": () => import("./../../../src/pages/watch.js" /* webpackChunkName: "component---src-pages-watch-js" */),
  "component---src-pages-xeno-on-mtn-momo-js": () => import("./../../../src/pages/xeno-on-mtn-momo.js" /* webpackChunkName: "component---src-pages-xeno-on-mtn-momo-js" */)
}

