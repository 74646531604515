import React from "react"
import Button from "./button"
import styled, { css } from "styled-components"
import { logEvent } from "../../utilities/logevent"
import { vw } from "../../utilities/pxtovw"
import Google from "../../images/badges/google.svg"
import Apple from "../../images/badges/apple.svg"

const Badges = styled.div`
  display: none;
  a {
    display: block;
    width: ${vw(136)};
    height: ${vw(37)};
    position: relative;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  @media (max-width: 766px) {
    display: flex;
    a {
      width: ${vw(400)};
      height: ${vw(108)};
    }
  }
`

const CtaWrapper = styled.div`
  @media (max-width: 766px) {
    display: none;
  }
`

const Ele = ({ title, to, id }) => {
  return (
    <>
      <Badges>
        <a
          href="https://play.google.com/store/apps/details?id=com.xeno.investment&showAllReviews=true"
          rel="noreferrer"
          target="_blank"
          className="google"
          id="app_download_link__google_play"
        >
          <img src={Google} alt="" />
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          className="apple"
          href="https://apps.apple.com/ug/app/xeno-investment/id1572436933#?platform=iphone"
          id="app_download_link__apple_store"
        >
          <img src={Apple} alt="" />
        </a>
      </Badges>

      <CtaWrapper>
        <Button
          id={id}
          label="Get Started"
          click={() => {
            logEvent("Home", title)
          }}
          to={to ? to : "https://join.myxeno.com"}
        />
      </CtaWrapper>
    </>
  )
}

export default Ele
