import { createGlobalStyle } from "styled-components"
import ZellikImg from "../images/tulumbe/zellij_pattern_1.png";

const SharedStyles = createGlobalStyle`
    html{
        scroll-behavior: smooth;
        font-size: 16px;
        --c-mint-100: #00e2c1;
        --c-green-200: #00c3a6;
        --c-blue-100: #12171F; 
        --c-blue-101: #131c2d; 
        --c-blue-200: #0d1016; 
        --c-blue-201: #0a0e15; 
        --c-black: #000; 
        --c-white: #d7d7d7;

        .ixs-mobile-img {
            display: none !important;
        }
        .x-img{
            width: 100%;
        }

        .mrc-desktop{
            display: block;
        }

        .mrc-mobile{
            display: none;
        }
        
        .mrc-hero-section {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            padding-bottom: 145px;
            height: 100vh;
        }

        .mrc-xcontainer {
            position: relative;
            z-index: 110;
            color: white;
            text-align: left;
            padding: 3rem;
            background-color: rgb(157 211 191 / 29%);
            margin: 2rem 0;
            border-radius: 30px;
            overflow: hidden;
        }

        .lumba {
            width: 72%;
            max-width: 72%;
            margin-left: 200px;
        }

        .xn-h2 {
            font-size: 50px;
        }

        .appDownloadActions {
            margin-left: 180px;
        }

        .x-font-size-4 {
            font-size:22px;
        }
        
        .pattern-1 {
            margin-right: -258px;
            margin-bottom: -53px;
            margin-top: 50px;
        }

        .pattern-2 {
            margin-top: 62px;
            margin-right: 268px;
            margin-bottom: -76px;
        }
        
        .pattern-3 {
            margin-top: 7px;
            margin-left: 215px;
            margin-bottom: -106px;
        }

        @media (min-width: 1900px) {
            font-size: 20px;
            .ixs-mobile-img {
                display: none !important;
            }

            /** Tugende Morocco styles **/
            .mrc-desktop{
                display: block;
            }

            .mrc-mobile{
                display: none;
            }

            .mrc-hero-section {
                display: flex;
                flex-direction: column;
                min-height: auto;
                padding-bottom: 145px;
            }

            .mrc-xcontainer {
                position: relative;
                z-index: 110;
                color: white;
                text-align: left;
                padding: 3rem;
                background-color: rgb(157 211 191 / 29%);
                margin: 2rem 0;
                border-radius: 30px;
                overflow: hidden;
            }
            .lumba {
                width: 72%;
                max-width: 72%;
                margin-left: 200px;
            }

            .pattern-1 {
                margin-right: -258px;
                margin-bottom: -53px;
                margin-top: 50px;
            }

            .pattern-2 {
                margin-top: 62px;
                margin-right: 268px;
                margin-bottom: -76px;
            }

            .pattern-3 {
                margin-top: 7px;
                margin-left: 215px;
                margin-bottom: -106px;
            }



        }

        @media (min-width: 1500px) {
            font-size: 18px;
            .ixs-mobile-img {
                display: none !important;
            }

            /** Tugende Morocco styles **/
            .mrc-desktop{
                display: block;
            }

            .mrc-mobile{
                display: none;
            }

            .mrc-hero-section {
                display: flex;
                flex-direction: column;
                min-height: auto;
                padding-bottom: 145px;
            }

            .mrc-xcontainer {
                position: relative;
                z-index: 110;
                color: white;
                text-align: left;
                padding: 3rem;
                background-color: rgb(157 211 191 / 29%);
                margin: 2rem 0;
                border-radius: 30px;
                overflow: hidden;
            }
            .lumba {
                width: 72%;
                max-width: 72%;
                margin-left: 200px;
            }

            .pattern-1 {
                margin-right: -258px;
                margin-bottom: -53px;
                margin-top: 50px;
            }

            .pattern-2 {
                margin-top: 62px;
                margin-right: 268px;
                margin-bottom: -76px;
            }


            .pattern-3 {
                margin-top: 7px;
                margin-left: 215px;
                margin-bottom: -106px;
            }

        }

        @media (min-width: 1919px) {
            font-size: 24px;
            .ixs-mobile-img {
                display: none !important;
            }

            /** Tugende Morocco styles **/
            .mrc-desktop{
                display: block;
            }

            .mrc-mobile{
                display: none;
            }

            .mrc-hero-section {
                display: flex;
                flex-direction: column;
                min-height: auto;
                padding-bottom: 145px;
            }

            .mrc-xcontainer {
                position: relative;
                z-index: 110;
                color: white;
                text-align: left;
                padding: 3rem;
                background-color: rgb(157 211 191 / 29%);
                margin: 2rem 0;
                border-radius: 30px;
                overflow: hidden;
            }
            .lumba {
                width: 72%;
                max-width: 72%;
                margin-left: 200px;
            }

            .pattern-1 {
                margin-right: -258px;
                margin-bottom: -53px;
                margin-top: 50px;
            }

            .pattern-2 {
                margin-top: 62px;
                margin-right: 268px;
                margin-bottom: -76px;
            }

            .pattern-3 {
                margin-top: 7px;
                margin-left: 215px;
                margin-bottom: -106px;
            }

        }

        @media (max-width: 1199px) {
            font-size: 12px;
            .ixs-mobile-img {
                display: none !important;
            }

            /** Tugende Morocco styles **/
            .mrc-desktop{
                display: block;
            }

            .mrc-mobile{
                display: none;
            }

            .mrc-hero-section {
                display: flex;
                flex-direction: column;
                min-height: auto;
                padding-bottom: 0px;
            }

            .mrc-xcontainer {
                position: relative;
                z-index: 110;
                color: white;
                text-align: left;
                padding: 3rem;
                background-color: rgb(157 211 191 / 29%);
                margin: 2rem 0;
                border-radius: 30px;
                overflow: hidden;
            }
            .lumba {
                width: 72%;
                max-width: 72%;
                margin-left: 200px;
            }

            .pattern-1 {
                margin-right: -258px;
                margin-bottom: -53px;
                margin-top: 50px;
            }

            .pattern-2 {
                margin-top: 62px;
                margin-right: 268px;
                margin-bottom: -76px;
            }

            .pattern-3 {
                margin-top: 7px;
                margin-left: 215px;
                margin-bottom: -106px;
            }

        }

        @media (max-width: 766px) {
            font-size: 15px;
            .xs-mobile {
                display: none;
            }
            .ixs-mobile-img {
                display: block!important;
                margin-bottom: -320px!important;
            }
            .ixs-mobile-container-margin {
                margin-top: 100px!important;
            }
            h1 {
                font-size: 1.4rem!important;
            }

            .xld-image{
                max-width: 134%;
                margin-left: -68px;
                margin-bottom: -95px;
            }


            .android-store {
                width: 150px!important;
            }

            .apple-store {
                width: 135px!important;
            }

            .xh1 {
                font-size: 1.4rem !important;
            }
            
            /** Tugende Morocco styles mobile **/
            .mrc-desktop{
                display: none!important;
            }

            .mrc-mobile{
                display: block;
            }
            
            .mrc-hero-img {
                width: 100% !important;
                height: auto !important;
                position: relative;
                background-size: cover;
                background-position: 50% 50%;
                 margin-top: 0px!important;
            }
            
            .mrc-hero-text {
                margin-top: 27px !important;
                margin-bottom: 25px;
                font-size: 12px !important;
                width: 99% !important;
                padding-left: 40px;
                padding-right: 40px;
            }

            .mrc-margin-top {
                margin-top: 73px;
            }

            .mrc-margintopxn {
                margin-top: 40px;
                margin-bottom: 40px;
            }

            .hero-morocco{
                width: 100%;
                padding: 1.5rem;
                height: auto!important;
            }
            
            }

        @media (max-width: 576px) {
            .mrc-margin-top {
                margin-top: 75px!important;
            }

            .mrc-hero-img {
                width: 100% !important;
                height: auto !important;
                position: relative;
                background-size: cover;
                background-position: 50% 50%;
                margin-top: 0px!important;
            }

            .mrc-hero-text {
                margin-top: 27px !important;
                margin-bottom: 25px;
                font-size: 12px !important;
                width: 99% !important;
                padding-left: 40px;
                padding-right: 40px;
            }
            .mrc-hero-section {
                display: flex;
                flex-direction: column;
                min-height: 70vh;
            }

            .mrc-margintopxn {
                margin-top: 147px;
                margin-bottom: 40px;
            }

           .mrc-app-access-action-padding {
               padding-left: 38px;
               padding-right: 38px;
            }
            .topLeftZellicPattern{
                position: absolute;
                top: -458px;
                left: -219px;
                width: 453px;
                height: 985px;
                background: url(${ZellikImg}) no-repeat center center/contain;
                z-index: 3;
                -webkit-clip-path: inset(200px 0 0 0);
                clip-path: inset(200px 0 0 0);
            }
            .phone-mobile {
                position: absolute;
                right: 11%;
                top: 3.5%;
                bottom: -9%;
                width: 316px;
                height: auto;
                object-fit: cover;
                z-index: 5;
            }
            
            .mrc-umtop{
                margin-top:300px;
            }
            .mrc-xcontainer {
                padding: 1.5rem !important;
                background-color: rgba(157, 211, 191, 0.15);
                margin: -2.6rem 0 !important;
                border-radius: 11px !important;
                margin-top: -46px !important;
                padding-bottom: 1px !important;
                overflow: hidden !important;
                width: auto;
                border: 1px solid #cccccc33 !important;
            }

            .mrc-xcontainer-0 {
                padding: 0rem !important;
                background-color: rgba(157, 211, 191, 0.15);
                margin-left: 0rem;
                margin-right: 0rem;
                border-radius: 11px !important;
                margin-top: 10px !important;
                padding-bottom: 1px !important;
                overflow: hidden !important;
                width: auto;
                border: 1px solid #cccccc33 !important;
            }

            .xn-h2 {
                font-size: 26px;
            }
            
            .xn-step{
                font-size: 11px!important;
                font-weight: 500;
                color: white;
                border: 1px solid #EBD666;
                border-radius: 38px;
                width: 33px!important;
                height: 15px!important;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
            }

            .p {
                font-size: 0.99rem;
                color: #fff;
                margin: 0.5rem 0 0;
            }

            .strong {
                font-size: 0.99rem !important;
                font-weight: bold;
            }

            .zellick-background {
                top: -65px !important;
                width: 744px !important;
                height: 300px !important;
                z-index: 10;
                pointer-events: none;
            }

            .slick-track {
                position: relative;
                top: 0;
                width: 100% !important;
                left: 0;
                display: flex !important;
                justify-content: center !important;
                margin-left: auto;
                margin-right: auto;
            }

            .mrc-text-margin {
                margin-top: 0rem;
                margin-bottom: 4.5rem;
            }

            .image-frame {
                width: 100%;
                position: relative;
                text-align: center;
                border-radius: 10px;
                overflow: hidden;
                padding-top: 8px;
                margin: 0 !important;
            }

            .xnbtn-lg {
                --bs-btn-padding-y: 0.2rem!important;
                --bs-btn-padding-x: 1rem!important;
                --bs-btn-font-size: 0.7rem!important;
                font-size: 0.7rem!important;
                padding: 2px 10px!important;
                --bs-btn-border-radius: var(--bs-border-radius-lg);
            }

            .button-overlay {
                position: absolute;
                bottom: 12px!important;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                border-radius: 15px;
                font-weight: bold;
                width: 100%;
            }

            .x-font-size {
                font-size: 0.99rem!important;
                padding-top: 2.5rem!important;
                padding-bottom: 1rem!important;
                padding-left: 1.5rem !important;
                padding-right: 3.5rem !important;
            }

            .x-font-size-2 {
                font-size: 0.99rem !important;
                padding-top: 2.5rem !important;
                padding-bottom: 2rem !important;
                padding-left: 1.5rem !important;
                padding-right: 2.1rem !important;
            }

            .x-font-size-3 {
                font-size: 0.99rem !important;
                padding-top: 0rem !important;
                padding-bottom: 0rem !important;
                padding-left: 0rem !important;
                padding-right: 0rem !important;
            }

            .x-font-size-4 {
                font-size: 0.99rem !important;
            }
            .mrcn-button {
                background: linear-gradient(90deg, #4F5CC5 0%, #6F7DD6 31%, #9AF4E7 60%);
                border: none;
                color: #000;
                font-size: 0.8rem!important;
                font-weight: bold;
                padding: 11px 20px!important;
                border-radius: 12px;
                margin-bottom: 36px;
                cursor: pointer;
                -webkit-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
                outline: none;
            }


            .normal-button {
                background: #010F27;
                border: none;
                color: #fff;
                font-size: 0.8rem !important;
                font-weight: bold;
                padding: 11px 20px !important;
                border-radius: 12px;
                margin-bottom: 0px;
                z-index: 4;
                cursor: pointer;
                -webkit-transition: 0.3s ease-in-out;
                -webkit-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
                outline: none;
            }

            .primary-button {
                background: #F2F9D8;
                border: none;
                color: #000;
                font-size: 0.8rem !important;
                font-weight: bold;
                padding: 11px 20px !important;
                border-radius: 2rem;
                margin-bottom: 36px;
                z-index: 4;
                cursor: pointer;
                -webkit-transition: 0.3s ease-in-out;
                -webkit-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
                outline: none;
            }

            .mrc-button-spacer {
                padding-left: 1.5rem !important;
                padding-right: 3.5rem !important;
            }
            
            .mrc-padding {
                margin-right: -3rem;
            }

            .xmrc-topnegativespace {
                margin-top: -70rem;
                margin-bottom: 6rem;
            }
            
            .faqtitle{
                font-size: 24px;
            }
            
            .faqpadding{
                margin-top: 2rem;
                margin-right: 4rem;
                margin-bottom: 1.5rem;
            }

            .gradient-background {
                background: linear-gradient(135deg, rgb(15 18 17 / 10%) 0%, #0d5c5169 100%);
                border-radius: 13px!important;
                margin-top: -39px!important;
            }

            .gradient-background-0 {
                background: linear-gradient(135deg, rgb(15 18 17 / 10%) 0%, #0d5c5169 100%);
                border-radius: 13px !important;
                margin-top: 0px !important;
            }
            
            .mxrc-top{
                margin-top: -114px!important;
            }
            .mxrc-title{
                font-size:27px!important;
                padding-bottom: 0!important;
                margin-top: 20px!important;
                text-align: center!important;
            }
            
            .mxrc-spacer {
                    margin-bottom: 104px;
                    text-align: center;
            }

            .mxrc-spacer-0 {
                margin-bottom: 20px;
                text-align: center;
            }

            .mxrc-spacer-text {
                margin-right: 1rem!important;
                margin-left: 1rem!important;
                margin-top: 20px !important;
            }
            
            .mxrc-button-spacer{
                padding-right: 2rem!important;
                padding-left: 2rem!important;
                margin-bottom: -78px!important;
                margin-top: 25px!important;
            }

            .mint-background {
                background: #00E2C1;
                border-radius: 13px!important;
                margin-top: -30px!important;
            }
            
            .mrcv-bottompadding{
                    margin-bottom: 0.4rem!important;
            }
            .xrd-reduce-margin {
                margin-right: -10px;
                margin-left: -10px;
                margin-top: -20px;
            }

            .xrd-reduce-margin-0 {
                margin-right: -12px;
                margin-left: -23px;
                margin-top: -42px;
            }
            
            .xrd-reduce-margin-1 {
                    margin-right: -25px;
                    margin-left: -23px;
                    margin-top: -42px;
            }
            
            .hero-wrapper{
                padding: 3rem 0.7rem;
            }


            .stepper-margin {
                margin-top: -4px;
            }

            .center-mobile-hero-ccontainer {
                display: flex;
                flex-direction: column; /* Stack elements vertically */
                align-items: center; /* Center horizontally */
                justify-content: center; /* Center vertically */
                height: auto; /* Full viewport height */
                text-align: center; /* Center text */
                margin-top: -500px!important;
            }

            .hero-morocco{
                width: 100%;
                padding: 1.5rem;
                height: auto!important;
            }

            .mrc-desktop{
                display: none!important;
            }

            .mrc-mobile{
                display: block!important;
            }



            .hero-subtitle {
                font-size: 1.33rem!important;
                margin-top: 10px;
            }


        }
        

        @media (prefers-color-scheme: light){
            --c-white: #f2f2f2; 
        }

        &.is-light {
            --c-white: #0d1016 !important; 
        }
    }
     body {
        -webkit-font-smoothing: antialiased;
        font-family: var(--xn-font-body);
        font-weight: 400;
        background: var(--c-blue-200);
        color: var(--c-white); 

        @media (prefers-color-scheme: light){
            background: var(--c-white); 
            color: var(--c-blue-200);
        }
    }

    h1,h2{
        font-family: var(--xn-font-title);
        font-weight: 700;
    }


    .xh1 {
        font-family: var(--xn-font-body), sans-serif!important;
        font-weight: 700!important;
        font-size: 2rem!important;
    }


    .gatsby-image-wrapper {
        height: 100%; 
    }
`
export default SharedStyles
